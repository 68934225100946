const CDN_BASE_URL = 'https://storage.googleapis.com/about-video';

export const CDN_URLS = {
  // Main Images
  SALAD: `${CDN_BASE_URL}/images/public/Salad.png`,
  VITA_VERDE_LOGO: `${CDN_BASE_URL}/images/public/Vita-Verde-Logo.png`,
  BOX: `${CDN_BASE_URL}/images/public/box.jpg`,
  BOX1: `${CDN_BASE_URL}/images/public/box1.jpg`,
  
  // Box Pictures
  BOXPIC1: `${CDN_BASE_URL}/images/boxpic1.jpg`,
  BOXPIC2: `${CDN_BASE_URL}/images/boxpic2.jpg`,
  BOXPIC3: `${CDN_BASE_URL}/images/boxpic3.jpg`,
  BOXPIC4: `${CDN_BASE_URL}/images/boxpic4.jpg`,
  BOXPIC5: `${CDN_BASE_URL}/images/boxpic5.jpg`,
  BOXPIC6: `${CDN_BASE_URL}/images/boxpic6.jpg`,
  BOXPIC7: `${CDN_BASE_URL}/images/boxpic7.jpg`,

  // Founder Images
  ALEX: `${CDN_BASE_URL}/images/public/alex.png`,
  MIKE: `${CDN_BASE_URL}/images/public/mike.png`,
  SHANE: `${CDN_BASE_URL}/images/public/shane.png`,

  // Customer Images
  CHRIS: `${CDN_BASE_URL}/images/public/images/chris.png`,
  ELI: `${CDN_BASE_URL}/images/public/images/eli.png`,
  HOLLY: `${CDN_BASE_URL}/images/public/images/holly.png`,
  MARGARET: `${CDN_BASE_URL}/images/public/images/margaret.png`,
  RUSS: `${CDN_BASE_URL}/images/public/images/russ.png`,
  
  // Product Images
  PRODUCE_DELIVERY: `${CDN_BASE_URL}/images/public/images/produce-delivery.jpg`,
  PRODUCE_DELIVERY2: `${CDN_BASE_URL}/images/public/produce-delivery2.jpg`,
  
  // Background Images
  CORKBOARD: `${CDN_BASE_URL}/images/public/corkboard.jpg`,
  LINEN: `${CDN_BASE_URL}/images/public/linen.jpg`,
  WOOD: `${CDN_BASE_URL}/images/public/wood.jpg`,
  FRIENDS_BG: `${CDN_BASE_URL}/images/public/friends-bg.jpg`,
  FRIENDS_BG_MOBILE: `${CDN_BASE_URL}/images/public/friends-bg-mobile.jpg`,
  CTA_BG: `${CDN_BASE_URL}/images/public/cta-bg.jpg`,
  // Feature Images
  CUSTOMER_PORTAL: `${CDN_BASE_URL}/images/public/customer_portal_thanksgiving.png`,
  DELIVERY_IMAGE: `${CDN_BASE_URL}/images/public/delivery-image.jpg`,
  DELIVERY_IMAGE_JPEG: `${CDN_BASE_URL}/images/public/delivery-image.jpeg`,
  ENJOY_IMAGE: `${CDN_BASE_URL}/images/public/enjoy-image.jpg`,
  FARM_SHIPPING: `${CDN_BASE_URL}/images/public/farm_shipping.jpg`,
  PLATE: `${CDN_BASE_URL}/images/public/plate.jpg`,
  
  // Produce Images
  PRODUCE_IMAGE: `${CDN_BASE_URL}/images/public/produce-image.jpg`,
  PRODUCE_IMAGE_JPEG: `${CDN_BASE_URL}/images/public/produce-image.jpeg`,
  SOURCE_PRODUCE: `${CDN_BASE_URL}/images/public/source-produce.jpg`,
  SUBSCRIPTION_IMAGE: `${CDN_BASE_URL}/images/public/subscription-image.jpg`,
  VEGGIE_BOXES: `${CDN_BASE_URL}/images/public/veggieboxes.png`,
  
  // Icons and App Assets
  FAVICON: `${CDN_BASE_URL}/images/public/favicon.ico`,
  LOGO_192: `${CDN_BASE_URL}/images/public/logo192.png`,
  LOGO_512: `${CDN_BASE_URL}/images/public/logo512.png`,
  
  // Other Assets
  MANIFEST: `${CDN_BASE_URL}/manifest.json`,
  ROBOTS: `${CDN_BASE_URL}/robots.txt`,
  INDEX: `${CDN_BASE_URL}/index.html`,
  
  // Step Images
  SUBSCRIPTION_IMAGE: `${CDN_BASE_URL}/images/public/subscription-image.jpg`,
  PRODUCE_IMAGE: `${CDN_BASE_URL}/images/public/produce-image.jpg`,
  SOURCE_PRODUCE: `${CDN_BASE_URL}/images/public/source-produce.jpg`,
  DELIVERY_IMAGE: `${CDN_BASE_URL}/images/public/delivery-image.jpg`,
  ENJOY_IMAGE: `${CDN_BASE_URL}/images/public/enjoy-image.jpg`,
  
  // Landing Page Images
  LANDING_PAGE_BG: `${CDN_BASE_URL}/images/public/landing_page-bg.jpg`,
};
