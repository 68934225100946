import React, { useState } from "react";
import './NavBar.css';
import { Link } from 'react-router-dom';
import { CDN_URLS } from './config/cdn';

const NavBar = ({ visible }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const closeMenu = () => {
        setIsMenuOpen(false);
    }

    return (
        <nav className={`navbar ${visible ? '' : 'navbar-hidden'}`}>
            <div className="navbar-logo">
                <Link to="/"><img src={CDN_URLS.VITA_VERDE_LOGO} alt="logo" /></Link>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <span className={isMenuOpen ? "bar open" : "bar"}></span>
                <span className={isMenuOpen ? "bar open" : "bar"}></span>
                <span className={isMenuOpen ? "bar open" : "bar"}></span>
            </div>
            <ul className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
                <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                <li><Link to="/checkout" onClick={closeMenu}>Purchase your delivery</Link></li>
                <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
                <li><Link to="/login" onClick={closeMenu}>Already a Customer?</Link></li>
            </ul>
        </nav>
    );
};

export default NavBar;