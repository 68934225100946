import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import ScrollToTop from "./ScrollToTop";
import DeliveriesPage from "./HowItWorks";  // Eager load home route
import LandingPage from "./LandingPage";     // Eager load landing page
import './LoadingFallback.css';

import './LoadingFallback.css';
import './CheckoutPage.css';
import './NavBar.css';
import './HomePage.css';
import './SubscriptionProducts.css';
import './CreateAccount.css';
import './Login.css';
import './ForgotPassword.css';
import './UserPage.css';
import './CustomizeDelivery.css';
import './AdminPage.css';
import './HowItWorks.css';
import './ReviewCarousel.css';
import './ProduceCarousel.css';
import './PictureGrid.css';

// Lazy load non-home routes
const HomePage = lazy(() => import("./HomePage"));
const SubscriptionProducts = lazy(() => import("./SubscriptionProducts"));
const CreateAccount = lazy(() => import("./CreateAccount"));
const Login = lazy(() => import("./Login"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const UserPage = lazy(() => import("./UserPage"));
const CustomizeDeliveryPage = lazy(() => import("./CustomizeDelivery"));
const AdminPage = lazy(() => import("./AdminPage"));
const CheckoutPage = lazy(() => import("./CheckoutPage"));
const RetargetPage = lazy(() => import("./RetargetPage"));

// Loading fallback component
const LoadingFallback = () => (
  <div className="loading-page">
    <div className="loading-content">
      <h1>Loading...</h1>
      <div className="spinner"></div>
    </div>
  </div>
);

function MainApp() {
  const location = useLocation();
  const hideNavbarRoutes = ['/create-account', '/login', '/forgot-password', '/fresh', '/admin'];
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <div>
      <ScrollToTop />
      {!shouldHideNavbar && <NavBar visible={visible} />}
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/how-it-works" element={<DeliveriesPage />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/customize" element={<CustomizeDeliveryPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/welcome-back" element={<RetargetPage />} />
          <Route path="/fresh" element={<LandingPage />} />
        </Routes>
      </Suspense>
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

export default App;