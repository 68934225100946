import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import { FaLeaf, FaTruck, FaClock, FaShieldAlt, FaCarrot, FaSeedling, FaBoxOpen, FaPiggyBank } from 'react-icons/fa';
import { CDN_URLS } from './config/cdn';
import ReviewCarousel from './ReviewCarousel';
import FoundersSection from './FoundersSection';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const valueProps = [
    {
      title: "Guaranteed Freshness",
      icon: <FaCarrot className="value-prop-icon" />,
      description: "Every box is packed with seasonal produce, harvested at peak ripeness and hand delivered to your door."
    },
    {
      title: "Better Quality, Lower Prices",
      icon: <FaSeedling className="value-prop-icon" />,
      description: "Get fresher, higher-quality produce for less than you'd pay at the grocery store. Skip the middleman and enjoy more value with every delivery."
    },
    {
      title: "Flexible Subscriptions",
      icon: <FaBoxOpen className="value-prop-icon" />,
      description: "Customize your box, pause anytime, and enjoy weekly, bi-weekly, or monthly deliveries that fit your lifestyle."
    }
  ];

  useEffect(() => {
    document.documentElement.style.setProperty('--wood-bg', `url(${CDN_URLS.WOOD})`);
    document.documentElement.style.setProperty('--linen-bg', `url(${CDN_URLS.LINEN})`);
    document.documentElement.style.setProperty('--friends-bg', `url(${CDN_URLS.FRIENDS_BG})`);
    document.documentElement.style.setProperty('--friends-bg-mobile', `url(${CDN_URLS.FRIENDS_BG_MOBILE})`);
    document.documentElement.style.setProperty('--cta-bg', `url(${CDN_URLS.CTA_BG})`);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) return; // Only run on mobile

    const handleScroll = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index);
          setActiveCardIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      root: document.querySelector('.value-props-grid'),
      threshold: 0.7
    });

    // Observe each card
    const cards = document.querySelectorAll('.value-prop-card');
    cards.forEach(card => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="landing-container">
      {/* Urgency Banner */}
      <div className="urgency-banner">
        <span className="urgency-banner-text">
            Freshness Waits for No One – Lock In Your Delivery Slot Before They're Gone!
        </span>
        <span className="urgency-banner-text-mobile">
    Freshness Waits for No One
  </span>
        <button 
          className="urgency-banner-cta" 
          onClick={() => navigate('/checkout')}
        >
          Lock In
        </button>
      </div>

      {/* Hero Section */}
      <section className="hero" style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                    url('${CDN_URLS.LANDING_PAGE_BG}')`
      }}>
        <h1 className="headline">
            Farm-Fresh, Delivered Better!
        </h1>
        {/* <p className="subheadline">
            Skip the supermarket guesswork—enjoy produce picked at peak ripeness and delivered without delay.
        </p> */}
        <button 
          className="cta-primary"
          onClick={() => navigate('/checkout')}
        >
          Start Your Order
        </button>
        <div className="hero-features">
          <div className="hero-feature">
            <FaPiggyBank /> Save $$$ vs Stores
          </div>
          <div className="hero-feature">
            <FaLeaf /> Local Farmers
          </div>
          <div className="hero-feature">
            <FaShieldAlt /> Satisfaction Guaranteed
          </div>
        </div>
      </section>

      {/* Value Propositions */}
      <section className="value-props">
        <h2 className="section-title">Why Join VitaVerde?</h2>
        <p className="section-subtitle">
            We're committed to delivering unmatched quality, sustainability, and convenience with every box. Here's what you'll get:
        </p>
        <div className="value-props-grid">
          {valueProps.map((prop, index) => (
            <div
              key={prop.title}
              data-index={index}
              className={`value-prop-card ${index === activeCardIndex ? 'active' : ''}`}
            >
              <h3>{prop.title}</h3>
              {prop.icon}
              <p>{prop.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Trust Elements */}
      <section className="trust-elements">
        <h2>Loved by Local Food Enthusiasts</h2>
        <div className="testimonials">
          <ReviewCarousel />
        </div>
      </section>

      {/* Founders Section */}
      <FoundersSection />

      {/* Call to Action Section */}
      <section className="cta-section">
        <div className="cta-content">
          <h2>Ready to Taste The Difference?</h2>
          <p>Join our community of food lovers and support local, sustainable farming.</p>
          <div className="cta-buttons">
            <button className="cta-primary" onClick={() => navigate('/checkout')}>
              Start Your Subscription
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;