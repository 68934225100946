import { CDN_URLS } from './config/cdn';
import './FoundersSection.css';

const FoundersSection = () => {
  const founders = [
    {
      name: "Shane",
      image: CDN_URLS.SHANE,
      bio: "A second-generation farmer from Half Moon Bay, Shane's family has cultivated over 60 acres south of the city. His deep-rooted connection to the land and commitment to quality ensure the best farm-to-table experience."
    },
    {
      name: "Michael",
      image: CDN_URLS.MIKE,
      bio: "With a decade of community service and deep roots in local farmers' markets, Mike focuses on building strong relationships with California farms to bring you the freshest local produce possible."
    },
    {
      name: "Alex",
      image: CDN_URLS.ALEX,
      bio: "Passionate about sustainability, Alex develops innovative farm-to-table systems that streamline food delivery. His tech-driven approach creates efficient networks between local farms and consumers, making fresh produce more accessible."
    }
  ];

  return (
    <section className="founders-section">
      <h2>Started by 3 Best Friends</h2>
      <div className="founders-grid">
        {founders.map((founder, index) => (
          <div key={index} className="founder-card">
            <img 
              src={founder.image} 
              alt={founder.name} 
              className="founder-image"
            />
            <h3 className="founder-name">{founder.name}</h3>
            <p className="founder-bio">{founder.bio}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FoundersSection;
